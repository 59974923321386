import {
    MapContainer,
    TileLayer,
    CircleMarker,
    Popup,
    useMap,
} from "react-leaflet";
import { useState, Fragment } from "react";
import DayPicker from "react-day-picker";
import { MARKERS } from "./dataMarkers";
import "react-day-picker/lib/style.css";
import "react-day-picker/lib/style.css";
import "./style.css";

const ACCESS_TOKEN =
    "pk.eyJ1Ijoic3NraWluZyIsImEiOiJjaWxhMDAzZHEwMGZ3dHVseHlyYzAxMTlwIn0.J5tYATi4UuE7qTY3RaJy0Q";

const colourGuide = {
    onsite: "#7415e6",
    offsite: "rgba(87,171,58,1)",
    program: "rgba(232,142,203,1)",
    partner: "rgba(231,123,64,1)",
    podcast: "rgba(232,142,203,1)",
};

const eventTypeGuide = {
    onsite: "Onsite project",
    offsite: "Offsite project",
    program: "Events and conversation",
    partner: "Partner project",
    podcast: "Podcast walk",
};

const today = new Date();
const todayString = today.toISOString().slice(0, 10);

function filterMarkers(selDate) {
    const removeMissingLatlngs = MARKERS.filter(
        (marker) =>
            marker.Publish === "TRUE" &&
            Array.isArray(marker.latLngs) &&
            marker.latLngs !== "[[]]"
    );

    return selDate
        ? removeMissingLatlngs.filter(
              (marker) =>
                  parseInt(selDate.replace(/-/g, "")) >= marker.startdate &&
                  parseInt(selDate.replace(/-/g, "")) <= marker.enddate
          )
        : removeMissingLatlngs.filter((marker) => marker.Publish === "TRUE");
}

function isRetinaDisplay() {
    if (window.matchMedia) {
        var mq = window.matchMedia(
            "only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)"
        );
        return (mq && mq.matches) || window.devicePixelRatio > 1;
    }
}

function App() {
    const [showFormat, setShowFormat] = useState("all");
    const [currentDate, setCurrentDate] = useState(todayString);
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(false);

    const filteredMarkers = filterMarkers(showFormat !== "all" && currentDate);

    return (
        <div>
            <MapContainer
                className="map-container"
                center={[-37.80780808690601, 145.000690579226]}
                zoom={13}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution=""
                    url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}${
                        isRetinaDisplay() ? "@2x" : ""
                    }?access_token={accessToken}`}
                    accessToken={ACCESS_TOKEN}
                    id={"sskiing/ckvj1cvxj18jp14qw0rcjzdb3"}
                    tileSize={512}
                    zoomOffset={-1}
                />
                {filteredMarkers.map((marker, index) => {
                    console.log(marker.latLngs);
                    return marker.latLngs.map((latLng, index2) => (
                        <CircleMarker
                            center={latLng}
                            key={`${index}${index2}`}
                            fillColor={colourGuide[marker.type]}
                            fillOpacity={1}
                            stroke={false}
                            radius={15}
                            id={`${marker.title}${marker.latLng}`}
                        >
                            <Popup
                                className={`event-${marker.type}`}
                                direction="top"
                                id={`${marker.title}${latLng}`}
                            >
                                <div className="pt-2 pr-2 pb-1 pl-0 w-64 whitespace-normal leading-tight">
                                    <h2 className="text-xl leading-tight mb-12">
                                        <div>{marker.artists}</div>
                                        <div>{marker.title}</div>
                                    </h2>
                                    <h3 className="text-base leading-tight pb-3">
                                        {eventTypeGuide[marker.type]}
                                        <div>{marker.display_date}</div>
                                    </h3>
                                    <h4 className="text-base">
                                        <div>{marker.address}</div>
                                    </h4>
                                    <div className="flex gap-3 mt-3 text-base">
                                        {marker.url && (
                                            <a
                                                href={marker.url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Details ↗
                                            </a>
                                        )}
                                        {marker.maps_url && (
                                            <a
                                                href={marker.maps_url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Directions ↗
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </Popup>
                        </CircleMarker>
                    ));
                })}
                <Menu
                    currentDate={currentDate}
                    isShowingDatePicker={isShowingDatePicker}
                    showFormat={showFormat}
                    setCurrentDate={setCurrentDate}
                    setIsShowingDatePicker={setIsShowingDatePicker}
                    setShowFormat={setShowFormat}
                    filteredMarkers={filteredMarkers}
                />
            </MapContainer>
        </div>
    );
}

const Menu = ({
    showFormat,
    setShowFormat,
    setIsShowingDatePicker,
    setCurrentDate,
    currentDate,
    isShowingDatePicker,
    filteredMarkers,
}) => {
    const map = useMap();

    let currentCategory = "";

    function handleOnFlyTo(idToMatch, latLng) {
        map.flyTo([latLng[0] + 0.001, latLng[1] + 0.0003], 18, {
            duration: 1,
        });
        map.eachLayer(function (layer) {
            if (layer._popup && layer._popup.options.id === idToMatch) {
                layer._popup._source.openPopup();
            }
        });
    }

    return (
        <div
            className="hidden md:block text-lg fixed w-1/3 max-w-lg top-0 right-0 h-screen p-3 antialiased"
            style={{ zIndex: 1000 }}
        >
            <div className="bg-black text-white flex flex-col h-full">
                <div className="p-3 pb-12 border-b border-gray-500">
                    <div className="">Show me:</div>
                    <div className="flex flex-row gap-3">
                        <button
                            className={`${
                                showFormat === "today" ? "font-bold" : ""
                            } cursor-pointer hover:opacity-80`}
                            onClick={(e) => {
                                setShowFormat("today");
                                setIsShowingDatePicker(false);
                                setCurrentDate(todayString);
                            }}
                        >
                            Today
                        </button>
                        <button
                            className={`${
                                showFormat === "date" ? "font-bold" : ""
                            } cursor-pointer hover:opacity-80`}
                            onClick={(e) => {
                                setShowFormat("date");
                                setIsShowingDatePicker(true);
                            }}
                        >
                            {showFormat === "date"
                                ? new Date(currentDate).toDateString()
                                : "Another date"}
                        </button>
                        {isShowingDatePicker && (
                            <div className="absolute top-20 right-32 bg-white p-4">
                                <DayPicker
                                    className="text-black"
                                    selectedDays={[
                                        new Date(
                                            currentDate.slice(0, 4),
                                            currentDate.slice(5, 7) - 1,
                                            currentDate.slice(8, 10)
                                        ),
                                    ]}
                                    onDayClick={(day, { selected }) => {
                                        setCurrentDate(
                                            day.toISOString().split("T")[0]
                                        );
                                        setIsShowingDatePicker(false);
                                    }}
                                />
                            </div>
                        )}
                        <button
                            className={`${
                                showFormat === "all" ? "font-bold" : ""
                            } cursor-pointer hover:opacity-80`}
                            onClick={(e) => {
                                setShowFormat("all");
                                setIsShowingDatePicker(false);
                            }}
                        >
                            Everything
                        </button>
                    </div>
                </div>

                <div className="p-3 flex-1 overflow-auto leading-snug">
                    {filteredMarkers.length === 0 ? (
                        <div className="flex-1 flex items-center justify-center">
                            <div>No events on this date.</div>
                        </div>
                    ) : (
                        <>
                            {/* <div className="items-center justify-center w-full py-4">
                                    <div>{filteredMarkers.length} events </div>
                                </div> */}
                            {filteredMarkers.map((item, index) => {
                                const output = (
                                    <Fragment key={index}>
                                        {currentCategory !== item.type ? (
                                            <div
                                                className={`text-2xl mb-3 ${
                                                    currentCategory !== ""
                                                        ? "mt-10"
                                                        : ""
                                                }`}
                                            >
                                                {eventTypeGuide[item.type]}s
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div
                                            onClick={(e) =>
                                                handleOnFlyTo(
                                                    `${item.title}${item.latLngs[0]}`,
                                                    item.latLngs[0]
                                                )
                                            }
                                            className="flex mb-4 pr-6 cursor-pointer hover:opacity-80"
                                            key={index}
                                        >
                                            <div
                                                className="flex-none w-3 h-3 relative top-1 rounded-full mr-4 bg-white  "
                                                style={{
                                                    backgroundColor:
                                                        colourGuide[item.type],
                                                }}
                                            ></div>
                                            <div>
                                                <h2>{item.artists}</h2>
                                                <h3>{item.title}</h3>
                                                <h4 className="opacity-50">
                                                    {item.partner &&
                                                        `${item.partner}`}
                                                </h4>
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                                currentCategory = item.type;
                                return output;
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default App;

const MARKERS = [
    {
        title: "ONSITE PROJECT",
        Publish: "FALSE",
        artists: "",
        partner: "",
        type: "",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: "",
        url: "",
    },
    {
        title: "Who's Afraid Of Public Space?",
        Publish: "TRUE",
        artists: "ACCA",
        partner: "",
        type: "onsite",
        "Event start date": "4/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address:
            "Australian Centre for Contemporary Art, 111 Sturt St, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.8267201008268, 144.96664592110696]],
        url: "https://acca.melbourne/exhibition/whos-afraid-of-public-space/",
    },
    {
        title: "DAS BOOT",
        Publish: "TRUE",
        artists: "",
        partner: "Curated by Oscar Perry and Esther Stewart",
        type: "onsite",
        "Event start date": "19/03/2022",
        "Event end date": "19/03/2022",
        display_date: "19 Mar 2022",
        startdate: 20220319,
        enddate: 20220319,
        address: "Northern Forecourt, ACCA, 111 Sturt St, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82620358193735, 144.96724054923408]],
        url: "",
    },
    {
        title: "EVENTS & CONVERSATIONS",
        Publish: "",
        artists: "",
        partner: "",
        type: "",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: "",
        url: "",
    },
    {
        title: "Opening:  ACCA Galleries",
        Publish: "TRUE",
        artists: "",
        partner: "",
        type: "program",
        "Event start date": "04/12/2021",
        "Event end date": "04/12/2021",
        display_date: "4 Dec 2021",
        startdate: 20211204,
        enddate: 20211204,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82675414812467, 144.96715197605127]],
        url: "",
    },
    {
        title: "Artist talks: ACCA Galleries",
        Publish: "TRUE",
        artists: "",
        partner: "",
        type: "program",
        "Event start date": "04/12/2021",
        "Event end date": "04/12/2021",
        display_date: "4 Dec 2021",
        startdate: 20211204,
        enddate: 20211204,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82683830428862, 144.96672836517692]],
        url: "",
    },
    {
        title: "Screening and talks: DESTINY",
        Publish: "TRUE",
        artists: "APHIDS",
        partner: "",
        type: "program",
        "Event start date": "11/12/2021",
        "Event end date": "11/12/2021",
        display_date: "11 Dec 2021",
        startdate: 20211211,
        enddate: 20211211,
        address: "Cardigan House Car Park 96 Grattan St, Carlton VIC 3053",
        maps_url: "https://goo.gl/maps/Jn8f6qFctz89yQWe8",
        latLngs: [[-37.80008457309934, 144.9657834550829]],
        url: "https://acca.melbourne/whos-afraid-of-public-space-offsite-aphids-destiny/",
    },
    {
        title: "Artist talks: ACCA Galleries",
        Publish: "TRUE",
        artists: "",
        partner: "",
        type: "program",
        "Event start date": "15/01/2022",
        "Event end date": "15/01/2022",
        display_date: "15 Jan 2022",
        startdate: 20220115,
        enddate: 20220115,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82683830428862, 144.96672836517692]],
        url: "https://acca.melbourne/program/artist-talks-simona-castricum-michael-candy-hoang-tran-nguyen-and-callum-morton/",
    },
    {
        title: "Artist talks: ACCA Galleries",
        Publish: "TRUE",
        artists: "",
        partner: "",
        type: "program",
        "Event start date": "05/02/2022",
        "Event end date": "05/02/2022",
        display_date: "5 Feb 2022",
        startdate: 20220205,
        enddate: 20220205,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82683830428862, 144.96672836517692]],
        url: "https://acca.melbourne/program/artist-talks-whos-afraid-of-public-space/",
    },
    {
        title: "Walking tour: David Wadelton",
        Publish: "TRUE",
        artists: "",
        partner: "",
        type: "program",
        "Event start date": "22/01/2022",
        "Event end date": "22/01/2022",
        display_date: "22 Jan 2022",
        startdate: 20220122,
        enddate: 20220122,
        address: "Gray & Gray, 188 High Street, Northcote VIC 3070",
        maps_url: "https://goo.gl/maps/Jn8f6qFctz89yQWe9",
        latLngs: [[-37.774823997783145, 144.99802505301133]],
        url: "https://acca.melbourne/program/walking-tour-david-wadelton-northcote-hysterical-society/",
    },
    {
        title: "Karaoke performance #1: Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "program",
        "Event start date": "20/01/2022",
        "Event end date": "20/01/2022",
        display_date: "20 Jan 2022",
        startdate: 20220120,
        enddate: 20220120,
        address: "Rear, 42A Albert Street, Footscray, Melbourne VIC 3011",
        maps_url: "https://goo.gl/maps/L5yJ9BSWPKN1gHCd8",
        latLngs: [[-37.802225484922204, 144.89753074713488]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Karaoke performance #2: Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "program",
        "Event start date": "16/02/2022",
        "Event end date": "16/02/2022",
        display_date: "16 Feb 2022",
        startdate: 20220216,
        enddate: 20220216,
        address:
            "University Square, University of Melbourne, Leister Street, Carlton, Melbourne VIC 3053",
        maps_url: "https://goo.gl/maps/KGc6HKU9zNj7ZXeV8",
        latLngs: [[-37.80093162901276, 144.9604433857727]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Karaoke performance #3: Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "program",
        "Event start date": "04/03/2022",
        "Event end date": "04/03/2022",
        display_date: "4 Mar 2022",
        startdate: 20220304,
        enddate: 20220304,
        address:
            "Westgate Park, 198 Lorimer Street, Port Melbourne, Melbourne VIC 3207",
        maps_url: "https://goo.gl/maps/HKxfqUJZdbDF9T3p8",
        latLngs: [[-37.83223062107269, 144.9059281415918]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Picnic and talks: Building Foundations",
        Publish: "TRUE",
        artists: "Beth Arnold and Sary Zananiri",
        partner: "",
        type: "program",
        "Event start date": "12/02/2022",
        "Event end date": "12/02/2022",
        display_date: "12 Feb 2022 12-2pm",
        startdate: 20220212,
        enddate: 20220212,
        address:
            "Eastern Pocket Park, corner of Bridge Road and Peridot Avenue, Officer, Melbounre VIC 3809",
        maps_url: "https://goo.gl/maps/3TQtGpeW21JRzhLB9",
        latLngs: [[-38.07430946295319, 145.430687632706]],
        url: "https://acca.melbourne/program/picnic-and-artist-talks-beth-arnold-and-sary-zananiri/",
    },
    {
        title: "OFFSITE PROJECTS",
        Publish: "FALSE",
        artists: "",
        partner: "",
        type: "",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: "",
        url: "",
    },
    {
        title: "Never Alone",
        Publish: "TRUE",
        artists: "Kent Morris",
        partner: "",
        type: "offsite",
        "Event start date": "3/8/2020",
        "Event end date": "30/8/2020",
        display_date: "3–30 Aug 2020",
        startdate: 20200803,
        enddate: 20200830,
        address:
            "Cnr Grey Street and Fitzroy Street, St Kilda, Melbourne VIC 3182",
        maps_url: "https://goo.gl/maps/1Lo9i4CeKPXHPCrG7",
        latLngs: [[-37.85959283158201, 144.97733680256007]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/kent-morris-never-alone/",
    },
    {
        title: "Parliament Steps Walking Drawing",
        Publish: "TRUE",
        artists: "Kerrie Poliness",
        partner: "",
        type: "offsite",
        "Event start date": "06/02/2021",
        "Event end date": "06/02/2021",
        display_date: "6 Feb 2021",
        startdate: 20210206,
        enddate: 20210206,
        address:
            "Parliament House Steps, Spring Street, East Melbourne VIC 3002",
        maps_url: "https://goo.gl/maps/JLPWZm8dznTkgdzv5",
        latLngs: [[-37.81126247804506, 144.973384842629]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/kerrie-poliness-parliament-steps-walking-drawing/",
    },
    {
        title: "ABORIGINAL LAND (SSID)",
        Publish: "TRUE",
        artists: "Steven Rhall",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82666982093209, 144.9670543127171]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-steven-rhall-aboriginal-land-ssid/",
    },
    {
        title: "Public Announcements",
        Publish: "TRUE",
        artists: "Laresa Kosloff",
        partner: "",
        type: "offsite",
        "Event start date": "14/02/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Feb–20 Mar 2022",
        startdate: 20211214,
        enddate: 20220320,
        address:
            "Arts Centre Melbourne covered walkway between Theatres Building and Hamer Hall, St Kilda Road, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/twDsNxZHj1JiiVZ98",
        latLngs: [[-37.820637949292255, 144.9683915206722]],
        url: "https://acca.melbourne/whos-afraid-of-public-space-offsite-laresa-kosloff-public-announcements/",
    },
    {
        title: "DESTINY",
        Publish: "TRUE",
        artists: "APHIDS",
        partner: "",
        type: "offsite",
        "Event start date": "11/12/2021",
        "Event end date": "11/12/2021",
        display_date: "11 Dec 2021",
        startdate: 20211211,
        enddate: 20211211,
        address: "Cardigan House Car Park 96 Grattan St, Carlton VIC 3053",
        maps_url: "https://goo.gl/maps/Jn8f6qFctz89yQWe8",
        latLngs: [[-37.80008457309934, 144.9657834550829]],
        url: "https://acca.melbourne/whos-afraid-of-public-space-offsite-aphids-destiny/",
    },
    {
        title: "From noxious trades to boutique bars",
        Publish: "TRUE",
        artists: "David Wadelton",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "Gray & Gray, 188 High Street, Northcote VIC 3070",
        maps_url: "https://goo.gl/maps/Jn8f6qFctz89yQWe9",
        latLngs: [[-37.774823997783145, 144.99802505301133]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-david-wadelton-from-noxious-trades-to-boutique-bars/",
    },
    {
        title: "From noxious trades to boutique bars",
        Publish: "TRUE",
        artists: "David Wadelton",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address:
            "Northcote Theatre, 216-218 High St Northcote, Northcote VIC 3070",
        maps_url: "https://goo.gl/maps/nQAx55XY4AS9RKC88",
        latLngs: [[-37.77381798270205, 144.99832000795925]],
        url: "",
    },
    {
        title: "From noxious trades to boutique bars",
        Publish: "TRUE",
        artists: "David Wadelton",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "M.A. Sedawie & Co, 567 High Street, Northcote VIC 3070",
        maps_url: "https://goo.gl/maps/BSECNhnaddMxLtiJ9",
        latLngs: [[-37.76359918381291, 144.99963430319912]],
        url: "",
    },
    {
        title: "Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "offsite",
        "Event start date": "20/01/2022",
        "Event end date": "20/01/2022",
        display_date: "20 Jan 2022",
        startdate: 20220120,
        enddate: 20220120,
        address: "Rear, 42A Albert Street, Footscray, Melbourne VIC 3011",
        maps_url: "https://goo.gl/maps/L5yJ9BSWPKN1gHCd8",
        latLngs: [[-37.802225484922204, 144.89753074713488]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "offsite",
        "Event start date": "16/02/2022",
        "Event end date": "16/02/2022",
        display_date: "16 Feb 2022",
        startdate: 20220216,
        enddate: 20220216,
        address:
            "University Square, University of Melbourne, Leister Street, Carlton, Melbourne VIC 3053",
        maps_url: "https://goo.gl/maps/KGc6HKU9zNj7ZXeV8",
        latLngs: [[-37.80093162901276, 144.9604433857727]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Work, Worker",
        Publish: "TRUE",
        artists: "Hoang Tran Nguyen",
        partner: "",
        type: "offsite",
        "Event start date": "04/03/2022",
        "Event end date": "04/03/2022",
        display_date: "4 Mar 2022",
        startdate: 20220304,
        enddate: 20220304,
        address:
            "Westgate Park, 198 Lorimer Street, Port Melbourne, Melbourne VIC 3207",
        maps_url: "https://goo.gl/maps/HKxfqUJZdbDF9T3p8",
        latLngs: [[-37.83223062107269, 144.9059281415918]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-hoang-tran-nguyen-work-worker/",
    },
    {
        title: "Keep Running",
        Publish: "TRUE",
        artists: "XYX Lab",
        partner: "",
        type: "offsite",
        "Event start date": "December 2021",
        "Event end date": "Dec",
        display_date: "6-27 Dec 2022",
        startdate: 20211206,
        enddate: 20211227,
        address: "Sturt Street, Southbank VIC 3006",
        maps_url: "",
        latLngs: [[-37.82620298594931, 144.96804369213552]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-monash-university-xyx-lab-keep-running/",
    },
    {
        title: "Keep Running",
        Publish: "FALSE",
        artists: "XYX Lab",
        partner: "",
        type: "offsite",
        "Event start date": "January 2022",
        "Event end date": "Jan",
        display_date: "10 Jan–18 Feb 2022",
        startdate: 20220110,
        enddate: 20220218,
        address: "Melbourne Metro",
        maps_url: "TODO MK: Map locations",
        latLngs: "",
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-monash-university-xyx-lab-keep-running/",
    },
    {
        title: "Keep Running",
        Publish: "TRUE",
        artists: "XYX Lab",
        partner: "",
        type: "offsite",
        "Event start date": "February 2022",
        "Event end date": "Jan",
        display_date: "3–24 Jan 2022",
        startdate: 20220103,
        enddate: 20220124,
        address: "121 Fitzroy Street St Kilda VIC  3182",
        maps_url: "https://goo.gl/maps/9d3j4Mb7GCt1smrG7",
        latLngs: [[-37.860070750287456, 144.97747952786773]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-monash-university-xyx-lab-keep-running/",
    },
    {
        title: "Keep Running",
        Publish: "TRUE",
        artists: "XYX Lab",
        partner: "",
        type: "offsite",
        "Event start date": "Feb",
        "Event end date": "March",
        display_date: "14 Feb – 7 Mar 2022",
        startdate: 20220214,
        enddate: 20220307,
        address: "Rayhur St Clayton",
        maps_url: "https://goo.gl/maps/SeXfyDx1dt34vKtC9",
        latLngs: [[-37.937360691384946, 145.1377296705316]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-monash-university-xyx-lab-keep-running/",
    },
    {
        title: "Public Meeting",
        Publish: "FALSE",
        artists: "Field Theory",
        partner: "",
        type: "offsite",
        "Event start date": "March 2022",
        "Event end date": "March 2022",
        display_date: "1–20 Mar 2022",
        startdate: 20220301,
        enddate: 20220320,
        address: "Muttama, New South Wales",
        maps_url: "",
        latLngs: [[]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-field-theory-public-meeting/",
    },
    {
        title: "Building Foundations",
        Publish: "TRUE",
        artists: "Beth Arnold and Sary Zananiri",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address:
            "Eastern Pocket Park, corner of Peridot Avenue and Celestine Drive, Officer, Melbounre VIC 3809",
        maps_url: "https://goo.gl/maps/3TQtGpeW21JRzhLB9",
        latLngs: [[-38.07430946295319, 145.430687632706]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-beth-arnold-and-sary-zananiri-building-foundations/",
    },
    {
        title: "",
        Publish: "FALSE",
        artists: "Simona Castricum",
        partner: "",
        type: "offsite",
        "Event start date": "TBC",
        "Event end date": "TBC",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: [[]],
        url: "",
    },
    {
        title: "Persistence of Vision",
        Publish: "TRUE",
        artists: "Michael Candy",
        partner: "",
        type: "offsite",
        "Event start date": "04/12/2021",
        "Event end date": "20/03/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "Brien Lane, Melbourne VIC",
        maps_url: "https://goo.gl/maps/T9Jtvtt4wiTX9b7m6",
        latLngs: [[-37.811911054069945, 144.9686283814078]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-michael-candy-persistence-of-vision/",
    },
    {
        title: "Your best is good enough for me",
        Publish: "TRUE",
        artists: "Jon Campbell",
        partner: "Metro Tunnel Creative Program",
        type: "offsite",
        "Event start date": "06/01/2022",
        "Event end date": "07/03/2022",
        display_date: "6 Jan–7 Mar 2022",
        startdate: 20220106,
        enddate: 20220307,
        address:
            "Metro Tunnel hoarding, City Square, Swanston Street, Melbourne VIC 3000",
        maps_url: "https://goo.gl/maps/bUCDkzv8iQf3qFfr9",
        latLngs: [[-37.8158090886409, 144.9669690944805]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-offsite-jon-campbell-your-best-is-good-enough-for-me/",
    },
    {
        title: "The Block",
        Publish: "TRUE",
        artists: "Guled Abdulwasi",
        partner: "Metro Tunnel Creative Program",
        type: "offsite",
        "Event start date": "06/01/2022",
        "Event end date": "07/03/2022",
        display_date: "6 Jan–7 Mar 2022",
        startdate: 20220106,
        enddate: 22020307,
        address:
            "Metro Tunnel hoarding, Federation Square, St Kilda Road, Melbourne VIC 3000",
        maps_url: "https://goo.gl/maps/rTrBGPK9cUsEP3PN9",
        latLngs: [[-37.817877614920256, 144.9681201862428]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/whos-afraid-of-public-space-whos-afraid-of-public-space-offsite-guled-abdulwasi-the-block/",
    },
    {
        title: "Nganga toornung-nge dharraga Bunjil [Looking down from the wings of Bunjil]",
        Publish: "TRUE",
        artists: "Keg de Souza",
        partner: "The Abbotsford Convent",
        type: "partner",
        "Event start date": "January 2022",
        "Event end date": "20/03/2022",
        display_date: "29 Jan–20 Mar 2022",
        startdate: 20220129,
        enddate: 22020320,
        address: "The Abbotsford Convent, 1 St Heliers St, Abbotsford VIC 3067",
        maps_url: "https://g.page/AbbotsfordConvent?share",
        latLngs: [[-37.80267725715478, 145.00281650818087]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/abbotsford-convent-keg-de-souza/",
    },
    {
        title: "PARTNER PROJECTS",
        Publish: "FALSE",
        artists: "",
        partner: "",
        type: "",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: [[]],
        url: "",
    },
    {
        title: "The Purple Shall Govern",
        Publish: "TRUE",
        artists: "Roberta Joy Rich",
        partner: "Footscray Community Arts",
        type: "partner",
        "Event start date": "20/01/2022",
        "Event end date": "27/03/2022",
        display_date: "20 Jan–27 Mar 2022",
        startdate: 20220120,
        enddate: 22020327,
        address:
            "Roslyn Smorgon Gallery, Footscray Community Arts, 45 Moreland St, Footscray VIC 3011",
        maps_url: "https://goo.gl/maps/hGEeYG8vrofigLmi7",
        latLngs: [[-37.80312701028857, 144.90693267921458]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/footscray-community-arts-roberta-joy-rich/",
    },
    {
        title: "Takeover",
        Publish: "FALSE",
        artists: "Curated by Grace McQuilten and Amy Spiers",
        partner: "Bus Projects",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "February 2022",
        display_date: "19 Feb 2022",
        startdate: 20220219,
        enddate: 20220219,
        address:
            "Victorian Parliament Steps, Spring St, East Melbourne VIC 3002",
        maps_url: "https://goo.gl/maps/JLPWZm8dznTkgdzv5",
        latLngs: [[-37.81126247804506, 144.973384842629]],
        url: "",
    },
    {
        title: "Takeover",
        Publish: "FALSE",
        artists: "Curated by Grace McQuilten and Amy Spiers",
        partner: "Bus Projects",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "February 2022",
        display_date: "3 Mar 2022",
        startdate: 20220303,
        enddate: 20220303,
        address:
            "Bus Projects, Collingwood Arts Precinct, 35 Johnston St, Collingwood VIC 3066",
        maps_url: "https://goo.gl/maps/r2bBNeiHdcwieRiq7",
        latLngs: [[-37.810033047804836, 144.96430493391034]],
        url: "",
    },
    {
        title: "Takeover",
        Publish: "FALSE",
        artists: "Curated by Grace McQuilten and Amy Spiers",
        partner: "Bus Projects",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "February 2022",
        display_date: "6 Mar 2022",
        startdate: 20220306,
        enddate: 20220306,
        address:
            "Bus Projects, Collingwood Arts Precinct, 35 Johnston St, Collingwood VIC 3066",
        maps_url: "https://goo.gl/maps/r2bBNeiHdcwieRiq7",
        latLngs: [[-37.810033047804836, 144.96430493391034]],
        url: "",
    },
    {
        title: "TO THE STREET",
        Publish: "TRUE",
        artists: "",
        partner: "Arts Project Australia",
        type: "partner",
        "Event start date": "December 2021",
        "Event end date": "March 2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220420,
        address: "254 Brunswick Street, Fitzroy VIC 3065",
        maps_url: "https://goo.gl/maps/wEWt8VenyUEQcckQ9",
        latLngs: [[-37.799747298877804, 144.97846173772632]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/arts-project-australia/",
    },
    {
        title: "TO THE STREET",
        Publish: "TRUE",
        artists: "",
        partner: "Arts Project Australia",
        type: "partner",
        "Event start date": "",
        "Event end date": "",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220420,
        address:
            "Arts Project Australia, Yards, Level 1, Perry Street Building Collingwood, 35 Johnston St, Collingwood VIC 3066",
        maps_url: "https://g.page/arts-project-australia-gallery?share",
        latLngs: [[-37.79929905727759, 144.98612569903202]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/arts-project-australia/",
    },
    {
        title: "Fringe Dwellers",
        Publish: "TRUE",
        artists: "",
        partner: "Blak Dot Gallery",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "March 2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220420,
        address: "Blak Dot Gallery, 33 Saxon St, Brunswick VIC 3056",
        maps_url: "https://goo.gl/maps/L38dVgbaQXf8WjMp9",
        latLngs: [[-37.770494567341174, 144.96059388577228]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/blak-dot-gallery/",
    },
    {
        title: "Test Sites phase 2",
        Publish: "TRUE",
        artists: "Morwenna Schenck",
        partner: "City of Melbourne / Testing Grounds",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "March 2022",
        display_date: "12 Feb–6 Mar 2022",
        startdate: 20220201,
        enddate: 20220304,
        address:
            "Signal Arts, Birrarung/Yarra River bank, Northbank, Flinders Walk, Melbourne VIC 3000",
        maps_url: "https://g.page/signalarts?share",
        latLngs: [[-37.81943788384917, 144.96327912804685]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/test-sites-phase-2-presented-by-city-of-melbourne-and-testing-grounds/",
    },
    {
        title: "Test Sites phase 2",
        Publish: "TRUE",
        artists: "Georgia Novak",
        partner: "City of Melbourne / Testing Grounds",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "March 2022",
        display_date: "19 Feb–27 Feb 2022",
        startdate: 20220201,
        enddate: 20220304,
        address:
            "China Town, Constellation lane, Little Bourke St, Melbourne VIC 3000",
        maps_url: "https://goo.gl/maps/o4zkzGXgNi7UEN8KA",
        latLngs: [[-37.81216576650143, 144.96641782166125]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/test-sites-phase-2-presented-by-city-of-melbourne-and-testing-grounds/",
    },
    {
        title: "Test Sites phase 2",
        Publish: "TRUE",
        artists: "Jenny Zhe Chang",
        partner: "City of Melbourne / Testing Grounds",
        type: "partner",
        "Event start date": "February 2022",
        "Event end date": "March 2022",
        display_date: "18 Feb–7 Mar 2022",
        startdate: 20220201,
        enddate: 20220306,
        address:
            "Testing Grounds, Queen Victoria Market, Queen St, Melbourne VIC 3000",
        maps_url: "https://goo.gl/maps/gbp1VzyLnxuupy3k6",
        latLngs: [[-37.808012727098514, 144.95677990639385]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/test-sites-phase-2-presented-by-city-of-melbourne-and-testing-grounds/",
    },
    {
        title: "Test Sites phase 2",
        Publish: "TRUE",
        artists: "Aarti Jadu",
        partner: "City of Melbourne / Testing Grounds",
        type: "partner",
        "Event start date": "March 2022",
        "Event end date": "March 2022",
        display_date: "Late Mar 2022",
        startdate: 20220201,
        enddate: 20220304,
        address:
            "Testing Grounds, Queen Victoria Market, Queen St, Melbourne VIC 3000",
        maps_url: "https://goo.gl/maps/uCztRH9FWx5gsFM69",
        latLngs: [[-37.810033047804836, 144.96430493391034]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/test-sites-phase-2-presented-by-city-of-melbourne-and-testing-grounds/",
    },
    {
        title: "Activators 8",
        Publish: "FALSE",
        artists: "",
        partner: "Chunky Move",
        type: "partner",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "North Forecourt, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "",
        latLngs: "",
        url: "",
    },
    {
        title: "PODCAST WALKS",
        Publish: "FALSE",
        artists: "",
        partner: "",
        type: "",
        "Event start date": "",
        "Event end date": "",
        display_date: "",
        startdate: "",
        enddate: "",
        address: "",
        maps_url: "",
        latLngs: [[]],
        url: "",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Idil Ali",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "510 Lygon Street, Carlton",
        maps_url: "https://goo.gl/maps/sjsjLF4RAFvsfMEm6",
        latLngs: [[-37.794649075835906, 144.96888025257556]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Timmah Ball",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "park at corner of Riverfront Way and Park Drive, Maribyrnong",
        maps_url: "https://goo.gl/maps/4ukx6C6o7W46o5pa7",
        latLngs: [[-37.762364203930495, 144.88561573809412]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Tony Birch",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "Collingwood Children's Farm",
        maps_url: "https://goo.gl/maps/pjNUXU9qn9wDVWPt6",
        latLngs: [[-37.80261168936327, 145.00531976530092]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Sophie Cunningham",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "Burke and Wills Monument, Royal Park, North Melbourne",
        maps_url: "https://goo.gl/maps/Kzb449xE6g91qtTg9",
        latLngs: [[-37.78888128338864, 144.95605699401452]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Eleanor Jackson",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address: "ACCA, 111 Sturt Street, Southbank VIC 3006",
        maps_url: "https://goo.gl/maps/R7tdxyWKKnDi2cw89",
        latLngs: [[-37.82685498405534, 144.96647383270914]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
    {
        title: "Six Walks",
        Publish: "TRUE",
        artists: "Christos Tsiolkas",
        partner: "",
        type: "podcast",
        "Event start date": "4/12/2021",
        "Event end date": "20/3/2022",
        display_date: "4 Dec 2021–20 Mar 2022",
        startdate: 20211204,
        enddate: 20220320,
        address:
            "Lonsdale Street at the corner of Waratah Place, Melbourne CBD",
        maps_url: "https://goo.gl/maps/HBLfhTsJTWBMoJu4A",
        latLngs: [[-37.811007592431196, 144.966838791536]],
        url: "https://acca.melbourne/whos-afraid-of-public-space/offsite/six-walks/",
    },
];
export { MARKERS };
